.bl-map {
    .ctrmap {
        img {
            @include w100bl;
        }
    }
    .ctrlegend {
        .ctx-schaerme & {
            background-color: $ras-brown;
        }
        .ctx-hotel & {
            background-color: $kh-blue;
        }

        padding-top: var(--layoutgridgap);
        padding-bottom: var(--layoutgridgap);

        .ctritems {
            @include grid;
            grid-template-columns: 1fr;
            @include breakpoint(small only) {
                row-gap:0.75em;
            }
            @include breakpoint(medium) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include breakpoint(large) {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .item {
            display: flex;
            align-items: center;
            gap:1em;

            .number {
                aspect-ratio: 1;
                height:1.75em;
                @include breakpoint(small only) {
                    height:1.25em;
                }
                background-color: white;                    

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }        
    }
}

//typo
.bl-map {
    .ctrlegend {
        color:$white;
    }
}