//styling
.bl-aktuelllist {
    .ctx-schaerme & {
        background-color: $ras-orange;
    }
    .ctx-hotel & {
        background-color: $kh-olive;
    }

    @include defaultsectionpadding;

    .item {
        @extend %cmp-card;
    }

    .morelink {
        margin-top: 1em;
        @include linkarrowright;
    }
}

//swiper
.bl-aktuelllist {
    //all swiper items same height
    .swiper-wrapper {
        align-items: stretch;
        .swiper-slide {
            height:auto;
        }
        .item {
            height:100%;
        }
    }

    //positioning of swiper next/prev buttons
    .ctrslider {
        position: relative;
    }
    .custom-button-prev, .custom-button-next {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        cursor: pointer;
        user-select: none;
        
        .icon {
            display: block;
            height:rem-calc(47);
            color:white;
        }
    }
    .custom-button-prev {
        @include breakpoint(medium) {
            left:rem-calc(-40);
        }
        @include breakpoint(small down) {
            top:30vw;
            left:rem-calc(20);
            z-index:1;
        }
    }
    .custom-button-next {
        @include breakpoint(medium) {
            right:rem-calc(-40);
        }
        @include breakpoint(small down) {
            top:30vw;
            right:rem-calc(20);
            z-index:1;
        }
    }
    .swiper-button-disabled {
        opacity: 0;
        cursor:default;
    }
}

//typo
.bl-aktuelllist {
    h1 {
        color:$white;
        margin-bottom: 0.75em;
    }
    h3 {
        .ctx-schaerme & {
            color:$ras-brown;
        }
        .ctx-hotel & {
            color:$kh-blue;
        }
    }
}
