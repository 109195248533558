$black:#000;
$white:#fff;
    $farbe1:#52277b;
    $farbe2:#ef2f69;
    $lightbg: mix($farbe1, #eee, 10%);
    $lightgray: #e8e8e8;

$bodybg: $white;

$textcolor:#000;

$allg-olive: #787855;
$allg-blue: #3C5055;

$ras-red: #B45F5F; // "middle"
$ras-orange: #BE824B; // "light"
$ras-brown: #8C4B41; // "dark"

$kh-green: #5A7869; // "middle"
$kh-olive: $allg-olive; // "light"
$kh-blue: $allg-blue; // "dark"

.bg-ras-red { background-color: $ras-red;  }
.bg-ras-orange { background-color: $ras-orange; }
.bg-ras-brown { background-color: $ras-brown; }

.bg-kh-green { background-color: $kh-green; }
.bg-kh-olive { background-color: $kh-olive; }
.bg-kh-blue { background-color: $kh-blue; }

.bg-allg-olive { background-color: $allg-olive; }
.bg-allg-blue { background-color: $allg-blue; }


$formerror: $ras-red;

/*
@mixin borderradius {
    border-radius: rem-calc(10);
}
*/

:root {
    --layoutgridgap:2rem;

    @include rem-calc-sl(--lrminmargin, 120, math.div(20, 120));
}

@mixin defaultsectionpadding {
    @include rem-calc-sl(padding-top, 100, 0.5);
    @include rem-calc-sl(padding-bottom, 100, 0.5);
}

@mixin linkarrowright {
    &::after {
        content: " →";
        //margin-left:0.25em;
    }
}