.bl-teamlist {
    .items {
        @include grid;
        @include breakpoint(large) {
            grid-template-columns: repeat(4, 1fr);
            &.itemsperrow-3 {
                grid-template-columns: repeat(3, 1fr);
            }
        }
        @include breakpoint(medium down) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint(small down) {
            grid-template-columns: repeat(1, 1fr);
        }

        .ctrimage {
            img {
                @include w100bl;
            }
        }
        .ctrimage:has(img:not([src])) {
            //Image is missing
            display:none;
        }
        .ctrtext {
            @include rem-calc-sl(padding-top, 25, 0.5);
            @include rem-calc-sl(padding-bottom, 30, 0.5);
            @include rem-calc-sl(padding-left, 20, 0.5);
            @include rem-calc-sl(padding-right, 20, 0.5);
            text-align: center;
            a {
                color:$black;
                text-decoration: underline;
            }
        }
        .ctrtext:has(li) {
            text-align: left;
        }
    }
}

//typo
.bl-teamlist {
    h3 {
        @include font-size-pt(16, 18);
        font-weight: bold;
        margin-bottom: 0.3em;
        text-transform: none;
    }
    .ctrtext {
        @include font-size-pt(16, 18);
        line-height: 1.277778;

        ul {
            @include bulletlist;
        }
    }
}

