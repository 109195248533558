%cmp-card {
    background-color: white;
    .ctrimg {
        padding: 4px;
        img {
            @include w100bl;
        }
    }
    .ctrinfo {
        @include rem-calc-sl(padding-left, 28, 0.5);
        @include rem-calc-sl(padding-right, 28, 0.5);
        @include rem-calc-sl(padding-top, 16, 0.5);
        @include rem-calc-sl(padding-bottom, 16, 0.5);
    }
}