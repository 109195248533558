.bl-logoslider {
    .ctrscroll {
        width: 100%;
        overflow: hidden;
    }
    .items {
        display:flex;
        align-items: center;
        width:fit-content;
    
        animation:bl-logoslider-marquee 20s linear infinite;
    }
    .item {
        display: block;
        @include rem-calc-sl(width, 180, 0.5);
        @include rem-calc-sl(margin-right, 70, 0.5);

        img {
            @include w100bl;
        }
    }
}

@keyframes bl-logoslider-marquee {
    0% {
        transform:translateX(0);
    }
    to {
        transform:translateX(-33.333%);
    }
}