:root {
    .ctx-schaerme {
        --border-color: white;
    }
    .ctx-hotel {
        --border-color: white;
    }
    .ctx-web {
        --border-color: black;
    }
}

#overlaymenu {
    .ctx-schaerme & {
        background-color: $ras-brown;
        color:$white;
    }
    .ctx-hotel & {
        background-color: $kh-blue;
        color: $white;
    }
    .ctx-web & {
        background-color: $white;
        color:$black;
    }

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    
    overflow-y:auto;
    scrollbar-width: thin;

    z-index: 100;

    .ctrclosebtn {
        padding-top:rem-calc(25);
        padding-bottom:rem-calc(25);
        .icon {
            cursor: pointer;
            display: block;
            margin-left:auto; //align right
            height:rem-calc(27);
        }
    }
    .ctrmenu {
        @include rem-calc-sl(padding-top, 140, 0.5);
        @include rem-calc-sl(padding-bottom, 70, 0.5);
    }

    //level1
    li.level1 > a {
        padding:0.5em 0;
    }
    li.level1 + li.level1 {
        border-top:1px solid var(--border-color);
    }
    li.level1.active > a {
        font-weight: bold;
    }


    //level2
    li.level2 > a {
        padding-left:3em;
        padding-top:0.4em;
        padding-bottom:0.4em;
    }
    li.level2:first-child > a {
        padding-top:0;
    }
    li.level2:last-child > a {
        padding-bottom:0.6em;
    }
    li.level2.active a {
        font-weight: bold;
    }

    //Hover

    li a:hover {
        opacity: 0.5;
    }

    //dropdown icon
    li.hassubmenu {
        position: relative;
        .icon-menuarrowdown {
            height:rem-calc(18);
            transition: all .2s;
            
            //make it easier to click element
            margin:-5px -10px;
            padding:5px 10px;

            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            padding: rem-calc(20 10 20 20);
            @include breakpoint (medium) {
                padding: rem-calc(23 10 23 20);
            }
            @include breakpoint (large) {
                padding: rem-calc(27 10 27 20);
            }

            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }

    }

    .expanded .icon-menuarrowdown {
        transform:rotate(90deg);
    }
}

//show/hide transitions
#overlaymenu {
    transition:opacity .4s;
    .ctrmenu {
        transition: transform .4s;
    }
}
#overlaymenu:not(.visible) { 
    pointer-events: none;
    opacity:0;
    .ctrmenu {
        transform: translateY(50px);
    }
}

//typo
#overlaymenu {
    li > a {
        text-transform: uppercase;
    }
    li.level1 > a {
        @include typo-menu;
    }
    li.level2 > a {
        @include typo-menu-level2;
    }
}