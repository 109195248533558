.history-wrapper {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
    padding: 50px 0;
    margin-bottom: 100px;
    .obj {
        position: relative;
		display: inline-block;
        height: 450px;
        margin-left: 20px;
        @media all and (min-width: 1200px) {
            margin-left: calc((100vw - 1160px)/2);
        }
        
        .picture {
            position: absolute;
            width: 100%;
            height: 450px;
            transition: all 1s;
            img {
                height: 450px;
                width: auto;
                max-width: none;
            }
        }
    }
}