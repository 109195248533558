//positioning
.bl-eventcalendar {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
        }
        .ctrcalendar {
            grid-column: 1 / span 5;
            align-self: start;
        }
        .ctreventlist {
            grid-column: 7 / span 6;
        }
    }
    @include breakpoint(medium down) {
        .ctreventlist {
            margin-top:1.5em;
        }
    }
}

//styling
.bl-eventcalendar {
    @include defaultsectionpadding;

    .ctx-schaerme & {
        background-color: $ras-orange;
    }
    .ctx-hotel & {
        background-color: $kh-olive;
    }
    
    color:$white;

    .ctrcalendar {
        border:7px solid currentColor;
        padding:0.75em 1em;
    }
    .icon-sliderleft, .icon-sliderright {
        height:rem-calc(25);
        cursor: pointer;
    }
    .monthselector {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    table.events {
        border-collapse: collapse;
        width:100%;
        text-align: center;

        margin-top:0.75em;

        tbody::before {
          content: '';
          display: block;
          height: 0.5em;
        }        
        th, td {
            padding:0.4em;
        }
        th {
            border-top:3px solid currentColor;
            border-bottom:3px solid currentColor;
        }
        td.othermonth {
            opacity: 0.3;
        }   
        td.currentday span {
            outline:3px solid currentColor;
            outline-offset: 0.2em;
        }
    }
    .ctreventlist {
        .event {
            border-top:3px solid currentColor;
            &:last-child {
                border-bottom:3px solid currentColor;
            }
        }
    }
}

//typo
.bl-eventcalendar {
    .ctrcalendar {
        @include font-size-pt(20, 25);
    }

    table.events th {
        font-weight: inherit;
    }
    .monthselector {
        font-weight:600;
        text-transform: uppercase;
    }

    .ctreventlist {
        h1 {
            margin-bottom: 0.25em;
        }
        h2 {
            font:inherit;
            margin-bottom: 0;
        }
        .event {
            padding: 0.5em 0;
        }
    }
}