body {
    --scrolldownbarheight:#{rem-calc(80)};
}

.bl-heroslider {
    position: relative;

    .mainimg {
        @include w100bl;
        object-fit: cover;
        object-position: center center;
        height:calc(100vh - var(--headerheight) - var(--scrolldownbarheight));

        body.ws60 & {
            object-position: 10% center;
        }
    }

    .heroslideroverlay {
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        opacity: 0.5;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .ctrscrolldownarrow {
        height:var(--scrolldownbarheight);
        
        display:flex;
        justify-content: center;
        align-items: center;

        .icon {
            height:rem-calc(16);
            aspect-ratio: 46 / 16;
            .ctx-schaerme & {
                color:#BE443F;
            }
            .ctx-hotel & {
                color: $kh-green;
            }

            cursor: pointer;
        }
    }

    .ctrswiper {
        position: relative;
    }
    .ctrbadge {
        position: absolute;
        z-index:5;
        
        @include rem-calc-sl(width, 196, 0.6);
        @include rem-calc-sl(right, 70, 0.5);
        @include rem-calc-sl(bottom, 85, 0.9);

        img {
            @include w100bl;
        }
    }
    .ctrbadge {
        transition: transform .2s;
        transform: rotate(-10deg);
        &[href]:hover {
            transform: rotate(0deg);
        }
    }
}

//swiper
.bl-heroslider {
    --swiper-theme-color:#fff;
    --swiper-pagination-color:#fff;
    --swiper-pagination-bullet-inactive-color:#909090;
    --swiper-pagination-bullet-inactive-opacity:1;
    --swiper-pagination-bullet-size:11px;
    --swiper-pagination-bullet-horizontal-gap:5px;
    --swiper-pagination-bottom:20px;

    .swiper-pagination {
        text-align: right;
        padding-right: 70px;
        box-sizing: border-box;
    }

    @include breakpoint(small down) {
        --swiper-pagination-bottom:10px;
        .swiper-pagination {
            padding-right: 10px;
        }
    }
}