.bl-kontaktformular {
    @include defaultsectionpadding;
}

//typo
.bl-kontaktformular {
    .subtitle {
        @include typo-h3;
        text-transform: none;
    }

    h2 {
        margin-bottom:0.3em;
        @include typo-h1;
        text-transform: none;
    }
    .subtitle {
        margin-bottom:1em;
    }
    h2, .subtitle {
        color:$ras-red;
    }
}

// bankett form
.bl-kontaktformular.bankett {
    color: $ras-orange;

    h2, .subtitle {
        color: $ras-orange;
    }

    .mmx-forms {
        input[type=text], input[type=email], textarea, select {
            border-color: $ras-orange;
        }

        .vf-checkbox-container, .vf-checkbox-wrapper {
            &:before {
                border-color: $ras-orange;
            }

            &:has(input:checked):before {
                background-color: $ras-orange;
            }
        }

        button {
            background-color: $ras-orange;
        }
    }
}

/*****
HOTEL
*****/
.ctx-hotel .bl-kontaktformular {
    color: $kh-green;

    h2, .subtitle {
        color: $kh-green;
    }

    .mmx-forms {
        input[type=text], input[type=email], textarea, select {
            border-color: $kh-green;
        }

        .vf-checkbox-container, .vf-checkbox-wrapper {
            &:before {
                border-color: $kh-green;
            }

            &:has(input:checked):before {
                background-color: $kh-green;
            }
        }

        button {
            background-color: $kh-green;
        }
    }
}

.ctx-hotel .bl-kontaktformular.bankett {
    color: $kh-olive;

    h2, .subtitle {
        color: $kh-olive;
    }

    .mmx-forms {
        input[type=text], input[type=email], textarea, select {
            border-color: $kh-olive;
        }

        .vf-checkbox-container, .vf-checkbox-wrapper {
            &:before {
                border-color: $kh-olive;
            }

            &:has(input:checked):before {
                background-color: $kh-olive;
            }
        }

        button {
            background-color: $kh-olive;
        }
    }
}