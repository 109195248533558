.bl-menuplan {
    .ctritems {
        @include grid;
        grid-template-columns: 1fr;
        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .item {
        border:7px solid var(--bordercolor);
        padding:1em;

        ul {
            @include reset-list;

            li {
                padding-top:0.75em;
                padding-bottom:0.75em;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                & + li {
                    border-top:3px solid var(--bordercolor);
                }
            }
        }
    }

    position: relative;
    .ctrbadge {
        position: absolute;
        z-index:10;
        
        @include rem-calc-sl(width, 158, 0.5);
        @include rem-calc-sl(right, 60, 0.5);
        @include rem-calc-sl(top, 40, 0.5);

        img {
            @include w100bl;
        }

        transform: rotate(-10deg);
    }
}

//typo
.bl-menuplan {
    h1 {
        margin-bottom: 0.75em;
    }
    h3 {
        color:var(--bordercolor);
    }
    .item {
        text-align: center;
    }
}

//bgcolor logic
.bl-menuplan {
    .ctx-schaerme &.bg-white {
        h1 {
            color:$ras-brown;
        }
        .item {
            --bordercolor:#{$ras-red};
            &:nth-child(6n+2) { --bordercolor:#{$ras-orange}; }
            &:nth-child(6n+3) { --bordercolor:#{$ras-brown}; }
            &:nth-child(6n+4) { --bordercolor:#{$ras-brown}; }
            &:nth-child(6n+6) { --bordercolor:#{$ras-orange}; }
        }
    }
    .ctx-hotel &.bg-white {
        h1 {
            color:$kh-blue;
        }
        .item {
            --bordercolor:#{$kh-green};
            &:nth-child(6n+2) { --bordercolor:#{$kh-olive}; }
            &:nth-child(6n+3) { --bordercolor:#{$kh-blue}; }
            &:nth-child(6n+4) { --bordercolor:#{$kh-blue}; }
            &:nth-child(6n+6) { --bordercolor:#{$kh-olive}; }
        }
    }
    &:not(.bg-white) {
        @include defaultsectionpadding;
        color:$white;
        --bordercolor:#{$white};
    }
}
