//positioning
.bl-impressionen {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
        }
        .ctrtitle {
            grid-column: 1 / span 5;
        }
        .ctrtext {
            grid-column: 7 / span 6;
        }
        .ctrimages {
            grid-column: 1 / span 12;
        }
    }
    @include breakpoint(medium down) {
        .ctrimages {
            margin-top:1.5em;
        }
    }
}

//styling
.bl-impressionen {
    .ctrbuttons {
        margin-top:2em;
        display:flex;
        justify-content: center;
    }
    .button {
        .ctx-schaerme & {
            background-color: $ras-brown;
            color:$white;
        }
        .ctx-hotel & {
            background-color: $kh-blue;
            color: $white;
        }
    }
    .image.hideonstart {
        display:none;
    }
}

//isotope
.bl-impressionen {
    .wconstrain {
        container-type: inline-size;
        --imgsize1:calc((100cqw - 3*var(--layoutgridgap)) / 4);
        --imgsize2:calc((100cqw - 1*var(--layoutgridgap)) / 2); 
        @include breakpoint(small down) {
            --imgsize1:calc((100cqw - 1*var(--layoutgridgap)) / 2);
            --imgsize2:100cqw; 
        }
    }
    .guttersizer {
        visibility: hidden;
        width: var(--layoutgridgap);
    }
    .columnsizer {
        visibility: hidden;
        width:var(--imgsize1);
    }
    .ctrimages {
        .image {
            position: relative;
            img {
                @include absposfull;
                object-fit: cover;
                //object-position: center 25%;
                object-position: center center;
            }
        }

        .image-1x1 {
            width:var(--imgsize1);
            height:var(--imgsize1);
        }
        .image-1x2 {
            width:var(--imgsize1);
            height:var(--imgsize2);
        }
        .image-2x1 {
            width:var(--imgsize2);
            height:var(--imgsize1);
        }
    }
}

//typo
.bl-impressionen {
    h1 {
        .ctx-schaerme & {
            color:$ras-brown;
        }
        .ctx-hotel & {
            color: $kh-blue;
        }
    }
    .ctrtext {
        @include typo-lead;
    }
}