body {
  //@include rem-calc-sl(--headerheight, 80, math.div(92, 170));
  --headerheight:#{rem-calc(80)};
}

@mixin logowidth {
  @include rem-calc-sl(width, 212, 0.5);
}
@mixin logotransform {
  transform: translateX(-10%);
  @include breakpoint(large) {
    transform: translateX(-22%);
  }
}

header {
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);

  .ctrlr {
    height:var(--headerheight);

    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  #mmx-search-root {
    position: absolute;
  }

  .ctrlogo {
    //logo inside header (Wortmarke)
    @include logotransform;
    @include logowidth;
    img {
      @include w100bl;
    }
  }

  .ctrmenu {
    display: flex;
    @include rem-calc-sl(gap, 45, 0.5);

    .btnmenu, .btnsearch {
      cursor: pointer;
    }
  }

  //sticky
  position:sticky;
  top:0;
  z-index: 10;
  background-color: $white;
}

//logo overlay with white background
#logooverlay {
  position: absolute;
  @include logowidth;
  top:0;
  z-index: 11;
  background-color: $white;
  @include logotransform;

  img {
    @include w100bl;
    padding: 11%;
  }
}

//full height overlay
.ctx-web #logooverlay {
  height:calc(100vh - var(--scrolldownbarheight));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem-calc(0 15);
  @include breakpoint(large) {
    padding: rem-calc(0 30);
  }
}

.popupfallback #logooverlay {
  display:none;
}

//typo
header {
  .ctrmenu {
    text-transform: uppercase;
    .ctx-schaerme & {
      color:$ras-red;
      .btnmenu {
        color:$ras-brown;
      }
      .btnsearch {
        color:$ras-orange;
      }
    }
    .ctx-hotel & {
      color:$kh-green;
      .btnmenu {
        color:$kh-blue;
      }
      .btnsearch {
        color:$kh-olive;
      }
    }
  }
}