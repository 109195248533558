//positioning
.bl-colorteaser {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
            @include gridrow1;
        }
        &.layout-tr {
            .ctrimg {
                grid-column: 1 / span 5;
            }
            .ctrtext {
                grid-column: 7 / span 6;
            }
        }
        &.layout-tl {
            .ctrimg {
                grid-column: 8 / span 5;
            }
            .ctrtext {
                grid-column: 1 / span 6;
            }
        }
    }
    @include breakpoint(medium down) {
        .ctrtext {
            margin-top:1em;
        }
        .ctrbuttons {
            display:flex;
            flex-direction: column;
            gap:0.5em;
        }
    }
}

//styling
.bl-colorteaser {
    @include defaultsectionpadding;

    .ctrimg img {
        border: 7px solid $white;
    }

    .bigimg {
        @include w100bl;
    }

    .ctrbuttons {
        margin-top: 2em;
        gap:0.5em;
    }    

    @include breakpoint(large) {
        &.layout-tl {
            .ctrtext {
                text-align: right;
            }
            .ctrbuttons {
                display:flex;
                justify-content: flex-end;
            }
        }
    }
}

//typo

.bl-colorteaser {
    color:$white;
    .leadtext {
        @include typo-lead;
    }
    /*
    .button {
        .ctx-schaerme & {
            color:$ras-brown;
        }
    }
    */
}
    