.bl-cards {
    @include defaultsectionpadding;

    .ctritems {
        @include grid;
        grid-template-columns: 1fr;
        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    
    .item {
        @extend %cmp-card;

        h3 {
            hyphens: auto;
        }

        .ctrimg img {
            aspect-ratio: 3 / 2;
            object-fit: cover;
            object-position: center center;
        }
    }

    
}

//typo
.bl-cards {
    h1 {
        color:$white;
        margin-bottom: 0.75em;
    }
    .ctx-schaerme & {
        h3 {
            color:$ras-brown;
        }
    }
    .ctx-hotel & {
        h3 {
            color:$kh-blue;
        }
    }
    .morelink {
        @include linkarrowright;
        margin-top:1.25em;
        margin-bottom: 1em;
    }
}

//Spezialstile fuer Eventlist
//https://dev.schaerme-kurhaus-sarnersee.pixmill.ch/kulinarik-und-events/
.bl-cards.layout-eventlist {
    h1 {
        .ctx-schaerme & {
            color:$ras-red;
        }
        .ctx-hotel & {
            color:$kh-green;
        }
    }
    .item {
        color:$white;
        h3 {
            color:inherit;
        }

        &:nth-child(6n+1) { background-color:$ras-brown; }
        &:nth-child(6n+2) { background-color:$ras-orange; }
        &:nth-child(6n+3) { background-color:$ras-red; }
        &:nth-child(6n+4) { background-color:$ras-red; }
        &:nth-child(6n+5) { background-color:$ras-orange; }
        &:nth-child(6n+6) { background-color:$ras-brown; }
    }

    .button {
        .ctx-schaerme & {
            background-color: $ras-brown;
            color:$white;
        }
        .ctx-hotel & {
            background-color: $kh-blue;
            color: $white;
        }
    }
    .ctrbuttons {
        margin-top:2em;
        display:flex;
        justify-content: center;
        gap:1em;
    }
}


//Spezielle Farben für Hintergrund 

.bl-cards.layout-eventlist:not(.bg-white) {
    .item {
        background-color:$white;
        color: $black; 
        h3 {
            color: $ras-red;
        }
    }
}