.mmx-forms {
    @include breakpoint(large) {
        .vf-row {
            @include grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        /*
        .sizespan2 {
            grid-column-end: span 2;
        }
        .sizespan3 {
            grid-column-end: span 3;
        }
        */
        .vf-element-layout:has(#name) { grid-row: 1; grid-column: 1; }
        .vf-element-layout:has(#adresse) { grid-row: 1; grid-column: 2; }
        .vf-element-layout:has(#telefon) { grid-row: 1; grid-column: 3; }
        .vf-element-layout:has(#vorname) { grid-row: 2; grid-column: 1; }
        .vf-element-layout:has(#plzort) { grid-row: 2; grid-column: 2; }
        .vf-element-layout:has(#email) { grid-row: 2; grid-column: 3; }
        .vf-element-layout:has(#nachricht) { grid-row: 3 / span 2; grid-column: 1; }
        .vf-element-layout:has(#kontaktart-Telefon) { grid-row: 3; grid-column: 2 / span 2; }
        .vf-element-layout:has(#datenschutz) { grid-row: 4; grid-column: 2 / span 2; }
        .ctrsubmit { grid-row:5; grid-column:1 / span 3; }
    }
    @include breakpoint(medium down) {
        .vf-element-layout + .vf-element-layout {
            margin-top:1em;
        }
    }

    .ctrsubmit {
        margin-left: auto;
    }

    input[type=text], input[type=email], textarea, select {
        border:2px solid $ras-red;
        //font:inherit;
        padding:0.6em 0.8em;

        width: 100%;
        box-sizing: border-box;

        outline:none;

        color:inherit;

        &::placeholder {
            color: inherit;
            opacity: 1;
        }
    }
    textarea {
        height:10em;
    }

    .vf-checkboxgroup-wrapper {
        display: flex;
        flex-direction: column;
    }
    .vf-checkbox-container, .vf-checkbox-wrapper {
        display: flex;
        gap:0.3em;
        align-items: flex-start;
        
        .vf-checkbox {
            //hide
            position: absolute;
            opacity: 0;
        }
        &:before {
            content:" ";
            width:0.5em;
            height:0.5em;
            padding:2px;
            border:2px solid $ras-red;
            background-clip: content-box;

            flex-shrink: 0;

            margin-top:0.3em;
        }
        &:has(input:checked):before {
            background-color: $ras-red;
        }

        user-select: none;
    }

    .button {
        color:$white;
        background-color: $ras-red;
        @include typo-base;
    }
}

//typo
.mmx-forms {
    .vf-element-error {
        color:$formerror;
        font-size:0.75em;
    }
    input, textarea {
        font:inherit;
        @include font-size-pt(15, 15);
        font-weight: 400;
        line-height: normal;    
    }
}
