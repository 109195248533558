footer {
  @include rem-calc-sl(padding-top, 30, 0.5);
  @include rem-calc-sl(padding-bottom, 30, 0.5);

  @include breakpoint(large) {
    .ctrmain {
      display: flex;
      justify-content: space-between;
    }
  }
  @include breakpoint(medium down) {
    .ctraddress, .ctrsmalllinks, .ctrsocialmisc {
      margin-top: 2em;
    }
  }

  .ctrlogo {
    img {
      display: block;
      @include rem-calc-sl(width, 114, 0.75); //Footer Logobreite
    }
  }

  .socialicons {
    display: flex;
    gap:0.25em;
    margin-top:0.5em;

    .icon {
      height:rem-calc(35);
      display: block;
    }
  }

  .copyright {
    margin-top:2em;
  }
}

//typo
footer {
  @include typo-footer;

  .impdslinks {
    text-transform: uppercase;
  }

  .smalllinks a {
    @include text-decoration-underline;
  }
}

//context specific colors
footer {
  .ctx-schaerme & {
    background-color: $ras-red;
    color:$white;
  }
  .ctx-hotel & {
    background-color: $kh-green;
    color: $white;
  }
  .ctx-web & {
    background-color: $white;
    color:$black;
    .ctrlogo {
      img {
        @include rem-calc-sl(width, 300, 0.75); //Footer Logobreite
      }
    }
  }
}