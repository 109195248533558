#mmx-search-modal {
    text-transform: none;
    .search-shortcuts {
        //Hide keyboard help
        display:none !important;
    }
    .search-actions:has(.xmark) {
        //Hide reset button
        display: none;
    }
    .search-results a.selected {
        background-color: #ccc !important;
    }
    .search-input {
        border-color:#ccc !important;
    }
    .modal-content {
        border-radius: 0;
        --bs-modal-border-color:#eee;
    }
    .form-control {
        border-radius: 0;
    }
    .bg-dark {
        background-color:$white !important;
        opacity: 0.9 !important;
    }
}