@mixin bulletlist {
  /*
  //V1: using pseudo element ::before
  margin-left: 1em;
  padding-left: 0;
  list-style-type: none;
  li {
    &::before {
      content: "– ";
      //content: "· ";
      //content: "• ";
      margin-left: -1em;
      margin-right: 0;
      width: 1em;
      display:inline-block;
    }
  }
  */

  //V2, using builtin symbols
  margin-left: 1em;
  padding-left: 0;
  list-style-type: disc;

  /*
  li::marker {
    color:$farbe2;
  }
  */
}

.bl-richtext ul,
.bl-textwithimage .coltext ul {
  @include bulletlist;
}
