:root {
  --btn-color: #000;

  .bg-ras-red { --btn-color: #{$ras-red}; }
  .bg-ras-orange { --btn-color: #{$ras-orange}; }
  .bg-ras-brown  { --btn-color: #{$ras-brown}; }
  .bg-kh-green { --btn-color: #{$kh-green}; }
  .bg-allg-olive { --btn-color: #{$allg-olive}; }
  .bg-allg-blue { --btn-color: #{$allg-blue}; }
}

//.buttonbar {}

.button {
  display: block;
  width: fit-content;
  padding: 0.2em 1.7em;
  color: var(--btn-color);
  background-color: $white;
  border:4px solid $white;
  transition: all .15s ease-in;
  &:hover {
    background-color: var(--btn-color);
    color: $white;
  }
  cursor: pointer;

}
button.button {
  //remove default <button> styling
  border: inherit;
  outline: none;
}

/*
.button {
  transition: background-color 0.2s;

  &:hover {
    background-color: $buttonbg-hover;
  }
}
*/




