.icon-sprites {
  display: none;
}

svg.icon {
  aspect-ratio: 1;
  height: 1em;
  //fill:currentColor;
}

svg.icon-sliderleft,
svg.icon-sliderright {
  aspect-ratio: 16/47;
}