.ctx-hotel-de {
    overflow: hidden;

    .container {
        width: 100%;
        height: 100dvh;
        position: relative;

        .bg-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 1;
        }
        .overlay-img {
            z-index: 2;
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
            img {
                opacity: 0.5;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }


    #logooverlay {
        height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 20px 0 20px;
        text-align: center;
        color: $kh-green;
        width: auto;
        a {
            img {
                margin: 0 auto;
                width: 106px;
                @include breakpoint (medium) {
                    width: 140px;
                }
                @include breakpoint (large) {
                    width: 180px; 
                }
            }
        }
        > div {
            line-height: 1.1;
            font-size: rem-calc(24);
            @include breakpoint (large) {
                font-size: rem-calc(60);
            }

            &:last-child {
                font-size: rem-calc(18);
                margin-bottom: 2em;
                @include breakpoint (large) {
                    font-size: rem-calc(35);
                }
            }
        }
    }


    .badge {
        position: absolute;
        right: 5%;
        bottom: 3%;
        z-index: 10;
        transition: all .15s ease;
        transform: rotate(-10deg);
        img {
            width: 100px;
            @include breakpoint (large) {
                width: 190px;
            }
        }
        &:hover {
            transform: rotate(0deg);
        }
    }

}


