section {
    @include rem-calc-sl(--baseunit, math.div(120,3), 0.5);
    @for $i from -5 through 10 {
        &.pt_#{$i} {
            padding-top:calc(#{$i} * var(--baseunit));
        }
        &.pb_#{$i} {
            padding-bottom:calc(#{$i} * var(--baseunit));
        }

        &.mt_#{$i} {
            margin-top:calc(#{$i} * var(--baseunit));
        }
        &.mb_#{$i} {
            margin-bottom:calc(#{$i} * var(--baseunit));
        }
    }
}
/*
section:first-child {
    padding-top:0;
}

section {
    --sectionbg: #{$bodybg}; //Used in form label overlay (_formfields.scss)
    &.bgcolor1 {
        background-color: $lightbg;
        --sectionbg: #{$lightbg};
    }
}
*/