@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(20, 20);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(30, 20);
}

body {
  font-family:'TT Neoris', sans-serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale;
  color:$textcolor;
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  @include font-size-pt(45, 80);
  font-weight: 400;
  line-height: math.div(90, 80);
}
@mixin typo-h2 {
  //@include font-size-pt(25, 40);
  @include font-size-pt(40, 80);
  font-weight: 400;
  line-height: normal;
}
@mixin typo-h2-small {
  @include font-size-pt(25, 40);
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@mixin typo-h3 {
  @include font-size-pt(25, 25);
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@mixin typo-h4 {
  @include typo-h3;
}

@mixin typo-lead {
  @include font-size-pt(24, 24);
  font-weight: 600;
  line-height:math.div(34, 24);
}
@mixin typo-footer {
  @include font-size-pt(16, 16);
  font-weight: 400;
  line-height:math.div(21, 16);
}
@mixin typo-menu {
  @include font-size-pt(20, 30);
  font-weight: 400;
  line-height:normal;
}
@mixin typo-menu-level2 {
  @include font-size-pt(16, 20);
  font-weight: 400;
  line-height:normal;
}
@mixin typo-breadcrumb {
  @include font-size-pt(16, 16);
  font-weight: 400;
  line-height:math.div(21, 16);
  text-transform: uppercase;
}

strong {
  font-weight: 600;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}

h1 {
  margin:0 0 0.4em 0;
}
h2, h3, h4 {
  margin:0 0 0.75em 0;
}
h1 {
  @include typo-h1;
}
h2 {
  @include typo-h2;
  &.small {
    @include typo-h2-small;
  }

  .ctx-schaerme & {
    color: $ras-brown;
  }
  .ctx-hotel & {
    color: $kh-blue;
  }

  .bg-ras-red & {
    color: $white;
  }
}
h3 {
  @include typo-h3;
}
h4 {
  @include typo-h4;
}
p + :is(h1,h2,h3,h4) {
  margin-top:1em;
}

@mixin contenttable {
  table {
    border-collapse:collapse;
  }
  th, td {
    border:1px solid $lightbg;
    padding:0.2em 0.5em;
  }
}

@mixin text-decoration-underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
}


//hyphens
@include breakpoint(small only) {
  .leadtext, .text {
      hyphens: auto;
  }
}
