//positioning
.bl-iconlist {
    .items {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-around;
        row-gap:var(--layoutgridgap);
    }
    .ctritem {
        width:40%;
        @include breakpoint(large) {
            width:22%;
        }
    }
}

//styling
.bl-iconlist {
    .iconimg {
        @include w100bl;
    }
}

//typo
.bl-iconlist {
    h3 {
        margin-bottom: 0;
        .ctx-schaerme & {
            color:$ras-brown;
        }
        .ctx-hotel & {
            color: $kh-blue;
        }
    }
    .ctrtext {
        margin-top:1.25em;
        text-align: center;
    }
    .morelink {
        @include linkarrowright;
    }
}