//styling
.bl-claim {
    @include defaultsectionpadding;
}

//typo {
.bl-claim {
    .claim {
        @include typo-h1;
        color:$white;
        text-align: center;
        text-transform: uppercase;
        
        max-width: 15em;
        margin-left: auto;
        margin-right: auto;
    }
}