//positioning
.bl-textwithimage {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
            @include gridrow1;
        }
        .ctrimg {
            grid-column: 1 / span 5;
        }
        &.imgw-small .ctrimg {
            grid-column: 1 / span 4;
        }
        .ctrtext {
            grid-column: 7 / span 6;
        }
    }
    @include breakpoint(medium down) {
        .ctrtext {
            margin-top:1em;
        }
    }
}

//styling
.bl-textwithimage {
    .bigimg {
        @include w100bl;
    }
    .item + .item {
        @include breakpoint(large) {
            @include rem-calc-sl(margin-top, 50, 0.5);
        }
        @include breakpoint(medium down) {
            @include rem-calc-sl(margin-top, 100, 0.5);
        }
    }
}

//typo
.bl-textwithimage {
    h2 {
        @include typo-h2-small;
    }
    h2, .downloadlist {
        .ctx-schaerme & {
            color:$ras-brown;
        }
        .ctx-hotel & {
            color: $kh-blue;
        }
    }
    .morelink {
        @include linkarrowright;
    }
    a {
        //text-decoration: underline;
    }
}

.downloadlist {
    margin-top: 1.5em;
    ul {
        @include reset-list;
        li {
            padding:0.4em 0;
            border-bottom:2px solid currentColor;

            a {
                display:flex;
                justify-content: space-between;
                align-items: flex-end;
                /*.icon {
                    xmargin-bottom: 0.05em;
                }*/
                text-decoration: none;
            }

            .icon {
                flex-shrink: 0;
            }
        }
    }
}