//positioning
.bl-gruppenfoto {
    @include breakpoint(large) {
        .personinfos {
            display:flex;
            justify-content: space-evenly;
            text-align: center;
            gap:1em;
        }
    }
    @include breakpoint(medium down) {
        .personinfo + .personinfo {
            margin-top:1em;
        }
    }
    .ctrimage {
        margin-top:3em;
    }
    .ctrnames {
        margin-top:1em;
    }
}

//styling
.bl-gruppenfoto {
    @include defaultsectionpadding;

    .ctrimage {
        img {
            @include w100bl;

            border: 7px solid $white;
        }
    }
}

//typo
.bl-gruppenfoto {
    color:$white;

    .ctrintrotext {
        .text {
            @include typo-lead;
        }
    }
    .ctrnames {
        @include font-size-pt(18, 18);
        font-weight: 400;
        line-height:math.div(24, 18);
    }
}