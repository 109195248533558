.popupmodal {
    display: none;
    position:fixed;
    inset: 0;
    z-index:1000;
    .bgoverlay {
        @include absposfull;
        background-color: rgba(255, 255, 255, 0.75);
    }
    .popup {
        background-color: var(--popupcolor, #666);
        color:$white;

        position: absolute;
        top:0;
        bottom:0;
        box-sizing: border-box;
        width:rem-calc(768);
        @include breakpoint(medium down) {
            width:100%;
        }
        //width:#{"min(764px, 100%)"};
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        overflow-y: auto;

        overscroll-behavior:none;
        scrollbar-width: thin; //or none

        @include rem-calc-sl(padding, 100, 0.5);
    }
    .icon-closepopup {
        cursor: pointer;

        color:var(--popupcolor, #666);

        position: absolute;
        right: rem-calc(26);
        top: rem-calc(26);
        height: rem-calc(26);
        @include breakpoint(medium down) {
            right: rem-calc(16);
            top: rem-calc(16);
            color:$white;
        }
    }
}

//resets
.popupmodal {
    .wconstrain {
        width:100%;
        max-width: none;
    }
}

//blockgaps
.popupmodal {
    @include rem-calc-sl(--defaultblockgap, 50, 0.5);
}

//style enhancements for blocks in popup
.popupmodal {

    h2 {
        @include font-size-pt(25, 40);
        text-transform: uppercase;
        color: $white;
    }
    .bl-imageblock + h2 {
        margin-top: 1em;
    }
    .bl-videoembed, .bl-imageblock {
        border:7px solid $white;
    }
    a {
        text-decoration: underline;
    }
}

//popup colors
.popupmodal:has([data-popupcolor=ras-red]) { --popupcolor:#{$ras-red}; }
.popupmodal:has([data-popupcolor=ras-orange]) { --popupcolor:#{$ras-orange}; }
.popupmodal:has([data-popupcolor=ras-brown]) { --popupcolor:#{$ras-brown}; }
