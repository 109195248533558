//Standardabstand zwischen zwei Bloecken
body {
    @include rem-calc-sl(--defaultblockgap, 100, 0.5);
}
.cblock + .cblock {
    margin-top:var(--defaultblockgap);
}

//Logik fuer Spacer
//(Vor und nach Spacer kein Abstand. Nur Abstand des Spacers selber.)
.cblock + .bl-spacer,
.bl-spacer + .cblock {
    margin-top:0;
}

//Spezifische Abstaende
:is(.bl-colorteaser, .bl-cards)  + :is(.bl-colorteaser, .bl-cards) {
    @include rem-calc-sl(margin-top, 30, 0.5);
}

/*
:is(.bl-imageblock, .bl-textwithimage) + :is(.bl-imageblock, .bl-textwithimage) {
    @include rem-calc-sl(margin-top, 70, 0.5);
}

.bl-title + .cblock {
    @include rem-calc-sl(margin-top, 60, 0.5);
}
*/


