.bl-richtext {
    /*
    &.dashlist {
        ul {
            @include reset-list;
        }
        li:before {
            //reset default bullet
            display: none;
        }

        li {
            border-top:2px dashed currentColor;
            padding:0.4em 0 0.2em 0;
        }
        ul {
            border-bottom:2px dashed currentColor;
            font-weight: 400;
        }
    }
    */
}

//typo
.bl-richtext {
    &.lead1 {
        @include typo-lead;
    }

    @include contenttable;
}


.alert {
    &--hotel-de {
        padding: 2em 2.5em;
        background-color: $kh-blue;
        color: $white;
    }
    &--schaerme {
        padding: 2em 2.5em;
        background-color: $ras-brown;
        color: $white;
    }
}
